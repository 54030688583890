@import "bootstrap";
@import "fonts";

// Colors
$red: #B82406;
$yellow: #FCCC2E;
$navy: #393E41;
$cream: #F2F0EA;
$gray: #c8c8c8;
$darkGray: #696969;

// Fonts
$univers-bold: UniversLTW01-67BoldCond;
$univers-cond: UniversLTW01-57Condensed;

// Universal
  body {
    font-family: Helvetica, sans-serif;
    &.media {
      margin-top: 0;
    }
  }

  h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
    font-family: $univers-bold;
  }

  .btn {
    font-family: $univers-cond;
    text-transform: uppercase;
    border-radius: 0;
    position: relative;
    padding: 2px 8px;
    .triangle {
      height: 26px;
      width: 12px;
      padding-right: 12px;
      position: absolute;
      right:-13px;
      top: -1px;
    }
    &.btn-warning {
      background: $yellow;
      border-color: $yellow;
      margin-right: 10px;
      color: $navy;
      .triangle {
        background: url(../images/inverted-yellow.png) no-repeat;
        background-size: 12px 52px;
        background-position: right 0;
      }
      &:hover, &:active, &:focus {
        background: darken($yellow, 10%);
        border-color: darken($yellow, 10%);
        color: $navy;
        box-shadow: none;
        .triangle { background-position: right -26px; }
      }
    }
    &.btn-primary {
      background: $navy;
      border-color: $navy;
      .triangle {
        background: url(../images/inverted-navy.png) no-repeat right;
        background-size: 12px 52px;
        background-position: right 0;
      }
      &:hover, &:active, &:focus {
        background: darken($navy, 10%);
        border-color: darken($navy, 10%);
        box-shadow: none;
        .triangle { background-position: right -26px; }
      }
    }
    &.btn-danger {
      background: $red;
      border-color: $red;
      .triangle {
        background: url(../images/inverted-red.png) no-repeat right;
        background-size: 12px 52px;
        background-position: right 0;
      }
      &:hover, &:active, &:focus {
        background: darken($red, 10%);
        border-color: darken($red, 10%);
        box-shadow: none;
        .triangle { background-position: right -26px; }
      }
    }
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  h4, .h4 {
    font-size: 22px;
  }

  h2, .h2 {
    margin-bottom: 25px;
    font-size: 34px;
  }

  h1, .h1, h2, .h2, h3, .h3 {
    &.bg {
      color: white;
      background: url(../images/pattern.png) repeat;
      display: inline-block;
      padding: 5px 10px;
    }
    &.red {
      color: $red;
    }
    &.yellow {
      color: $yellow;
    }
    &.navy {
      color: $navy;
    }
  }

  section, footer {
    padding: 50px 0;
  }

  a {
    color: $red;
    &:hover, &:focus, &:active {
      color: #871a04;
      text-decoration: none;
    }
  }

  .breadcrumb {
    padding: 8px 0;
    background: none;
  }

  .progress-bar {
    background-color: $red;
  }

// Header
header {
  background: $cream;
  padding: 30px 0;
  .brand-logo {
    img {
      width: 100%;
      max-height: 58px;
    }
  }
  .number {
    display: inline-block;
    font-size: 28px;
    line-height: 21px;
    font-family: $univers-cond;
  }
  .tagline {
    display: inline-block;
    background: $yellow;
    color: $navy;
    padding: 3px 5px;
    text-transform: uppercase;
    font-family: $univers-cond;
  }
  .connect {
    font-family: $univers-cond;
    color: $darkGray;
    font-size: 20px;
    line-height: 18px;
    margin-bottom: 6px;
  }
  .social {
    list-style-type: none;
    li {
      float: right;
      background: $yellow;
      height: 30px;
      margin-right: 3px;
      width: 30px;
      border-radius: 15px;
      text-align: center;
      &:first-child {
        margin-right: 0;
      }
      &:hover {
        background: darken($yellow, 10%);
      }
      a {
        color: $navy;
        display: block;
        font-size: 15px;
        line-height: 30px;
        height: 30px;
        width :30px;
      }
    }
  }
}


// Nav
.navbar-default {
  margin-bottom: 0;
  border: none;
  border-radius: 0;
  min-height: 42px;
  background: $red;
}
.navbar-default .navbar-nav {
  float: none;
  text-align: center;
  > li {
    float: none;
    display: inline-block;
    margin-right: 15px;
    > a {
      color: white;
      font-family: $univers-cond;
      text-transform: uppercase;
      font-size: 18px;
      padding: 11px 15px;
      &.active, &:hover, &:focus {
        background: darken($red, 10%);
        color: rgba(255,255,255,0.9);
      }
    }
  }
}


// Home
  // Jumbotron
  .jumbotron {
    padding: 0;
    margin-bottom: 0;
  }

  .category {
    background: $navy url(../images/earth-moving) no-repeat;
    background-size: cover;
    height: 315px;
    background-position: center;
    cursor: pointer;
    &.earth-moving {
      background-image: url(../images/earth-moving.jpg);
    }
    &.demolition {
      background-image: url(../images/demolition.jpg);
    }
    &.recycling {
      background-image: url(../images/recycling.jpg);
    }
    &.work-tools {
      background-image: url(../images/work-tools.jpg);
    }
  }

  .jumbotron h1 {
    margin: 0;
    margin-top: 30px;
    font-size: 36px;
    display: block;
    float: left;
  }

  .jumbotron .btn {
    clear: both;
    display: block;
    float: left;
  }

  // Associations
  .associations {
    list-style-type: none;
    li {
      margin-bottom: 20px;
    }
    .national-demolition {
      height: 52px;
    }
    .ieda {
      height: 75px;
      margin-bottom: -15px;
    }
    .isri {
      height: 70px;
    }
  }
  // Facility
  .map-link {
    img {
      height: 125px;
    }
  }
  // Featured
  .featured {
    background: $navy url(../images/bg-redtint.jpg) no-repeat center;
    background-size: cover;
  }

  .featured .media {
    background: $navy;
    padding-right: 5px;
    margin-top: 20px;
    .media-heading {
      color: white;
      margin-top: 8px;
    }
    h4.media-price {
      color: $yellow;
      font-family: $univers-cond;
      font-size: 18px;
      margin-top: 6px;
      margin-bottom: 6px;
    }
    p {
      color: $gray;
      height: 72px;
      overflow: hidden;
      line-height: 18px;
    }
    a {
      color: white;
      text-transform: uppercase;
      font-family: $univers-cond;
    }
  }

  // Content
  .content {
    padding-bottom: 40px;
    img {
      width: 100%;
    }
    strong {
      font-size: 16px;
      font-family: $univers-cond;
      text-transform: uppercase;
      color: $red;
    }
    .bio {
      strong {
        width: 100%;
      }
      span {
        clear: both;
        display: block;
      }
    }
    dl {
      margin-bottom: 0;
    }
    dt {
      margin-bottom: 1px;
    }
    dd {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    iframe {
      max-width: 100%;
    }
    .sidebar {
      a.inventory-link {
        display: block;
        margin-bottom: 10px;
      }
      h3 {
        margin-top: 0;
      }
    }
  }

  // Team
  .team {
    .left img {
      margin-bottom: 20px;
    }
    h3 {
      margin-top: 0;
    }
  }

  // Facility
  .facility {
    .left img {
      margin-bottom: 10px;
    }
    h3 {
      margin-top: 0;
    }
  }

  // Inventory
  .inventory {
    table > tbody > tr > td {
      vertical-align: middle;
    }
    .controls {
      padding-top: 28px;
    }
    .topbar {
      margin-bottom: 15px;
    }
    .showall {
      margin-top: 32px;
      display: inline;
      margin-left: 10px;
      cursor: pointer;
    }
    .pagination > li:last-child > a, .pagination > li:last-child > span, .pagination > li:first-child > a, .pagination > li:first-child > span {
      border-radius: 0;
    }
    .pagination {
      border-radius: 0;
      > li > a, > li > span {
        color: $red;
      }
      > li.disabled > a.page-link  {
        color: #777777;
      }
    }
  }

  // Inventory Show
  .inventory_show {
    h2 {
      margin-top: 0;
      margin-bottom: 10px;
    }
    h3.price {
      color: $darkGray;
      margin-top: 0;
    }
    .details {
      strong {
        display: inline-block;
        width: 100%;
      }
      span {
        width: 100%;
        display:inline-block;
        margin-bottom: 8px;
      }
      iframe {
        margin-bottom: 8px;
        margin-top: 4px;
      }
    }

      .machine__thumbnails {
        display: -webkit-flex;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
      }

      .machine__picture-box {
        flex-basis: calc(33% - .25rem);
        margin: .5rem 0 0;

        &:first-child {
          flex-basis: 100%;
          margin: 0;
          text-align: center;
        }

        &:nth-child(3n+4) {
          margin-right: 0;
        }

        &:nth-child(n+8) {
          display: none;
        }
      }
      .machine__picture {
        max-width: 100%;
      }
      #show-all-pictures {
        margin-top: 10px;
      }
  }

  // Footer
    footer {
      background: $cream;
    }
    // Links
    footer ul li {
      float: left;
      width: 50%;
      > a {
        color: $darkGray;
      }
    }

    footer form .btn {
      margin-top: 10px;
    }

    footer .logo-mark {
      margin-top: 15px;
    }

  // Image Modal
    .modal.images {
      .close {
        position: relative;
        top: 12px;
        right: 14px;
        color: #fff;
        text-shadow: none;
        font-size: 22px;
        opacity: 1;
        z-index: 9999;
      }
      .controls {
        position: absolute;
        bottom: 14px;
        width: 100%;
        text-align: center;
        .btn:first-child {
          margin-right: 15px;
        }
      }
      .image-container {
        position: relative;
        height: 100%;
        width: 100%;
      }
      img {
        position: absolute;
        top: 50%;
        max-height: 80%;
        max-width: 80%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }
    }

@import "_mobile.scss";
