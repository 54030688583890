@media (max-width: 768px) {
  h2 {
    margin-top: 40px;
  }

  section {
    padding: 0;
    padding-bottom: 40px;
  }

  header {
    padding: 20px;
    .col-xs-12 {
      padding: 0;
    }
    .left {
      text-align: center;
      margin-bottom: 15px;
      .number {
        font-size: 24px;
        display: block;
        line-height: 18px;
        margin-bottom: 5px;
      }
      .tagline {
        font-size: 12px;
      }
    }
  }

  .navbar-default .navbar-collapse {
    padding: 0;
    border-color: transparent;
  }

  .navbar-toggle {
    border: none;
  }

  .navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover  {
    background-color: darken($red, 5%);
  }

  .navbar-default .navbar-toggle .icon-bar {
    background-color: white;
  }

  .navbar-default .navbar-text {
    float: left;
    padding-left: 15px;
    color: white;
    font-family: $univers-cond;
    font-size: 18px;
    margin: 11px 0;
  }

  .navbar-default .navbar-nav {
    margin: 0;
    > li {
      margin: 0;
      width: 50%;
      float: left;
    }
  }

  footer {
    padding: 0;
    padding-bottom: 20px;
    h4 {
      margin-top: 20px;
    }
  }

  .featured {
    background: $navy url(../images/bgm-redtint.jpg) no-repeat center;
    background-size: cover;
  }
  .featured .media {
    padding-right: 0;
    .media-left {
      display: block;
      width: 100%;
      padding: 0;
      .media-object {
        width: 100%;
      }
    }
    .media-body {
      padding: 10px;
      h4.media-heading {
        margin-top: 0;
      }
    }
  }
  /* Space between map and form on contact page */
  .contact form {
    margin-bottom: 15px;
  }

  /* Inventory Show */
  .inventory_show .selected_image {
    img {
      max-height: 350px;
    }
  }
  .inventory_show {
    h2 {
      font-size: 30px;
    }
    .controls {
      margin-bottom: 15px;
    }
  }
}

@media (max-width: 670px) {
  /* Makes table mobile friendly */
  table.mobile {
    border: 0;
    margin-top: 20px;
  }

  table.mobile thead {
    display: none;
  }

  table.mobile > tbody > tr {
    margin-bottom: 25px;
    display: block;
    border-bottom: 1px solid #E0DDD5;
    background: none !important;
  }

  table.mobile td {
    display: block;
    text-align: right;
    font-size: 13px;
    height: 35px;
    width: 100% !important;
    border-bottom: none !important;
    &[data-label="Actions"] {
      height: auto;
    }
  }

  table.mobile td:last-child {
    border-bottom: 0;
  }

  table.mobile td:nth-child(2) {
    border-top: 2px solid $red;
  }

  table.mobile td:before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
  }
}
