@font-face {
	font-family: 'UniversLTW01-57Condensed';
	src: url('../fonts/UniversLTW01-57Condensed.eot');
	src: url('../fonts/UniversLTW01-57Condensed.eot?#iefix') format('embedded-opentype'),
		url('../fonts/UniversLTW01-57Condensed.woff') format('woff'),
		url('../fonts/UniversLTW01-57Condensed.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'UniversLTW01-67BoldCond';
	src: url('../fonts/UniversLTW01-67BoldCond.eot');
	src: url('../fonts/UniversLTW01-67BoldCond.eot?#iefix') format('embedded-opentype'),
		url('../fonts/UniversLTW01-67BoldCond.woff') format('woff'),
		url('../fonts/UniversLTW01-67BoldCond.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}